import type {ThemeDefinition} from "vuetify";

export const lightTheme:ThemeDefinition = {
    dark:false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#002951',
        secondary: '#e8e8e4',
        accent: '#80cc28',
        "on-accent": "white",
        error: '#f62e36',
        info: '#50accb',
        success: '#ccea9e',
        warning: '#fcc812',
        textbox: '#FFF'
    },
    variables: {
        'border-color': '#000000',
        'border-opacity': 0.12,
        'high-emphasis-opacity': 0.87,
        'medium-emphasis-opacity': 0.60,
        'disabled-opacity': 0.38,
        'activated-opacity': 0.12,
        'idle-opacity': 0.04,
        'hover-opacity': 0.12,
        'focus-opacity': 0.12,
        'selected-opacity': 0.08,
        'dragged-opacity': 0.08,
        'pressed-opacity': 0.16,
        'kbd-background-color': '#212529',
        'kbd-color': '#FFFFFF',
        'code-background-color': '#C2C2C2',
    }
}

export const darkTheme:ThemeDefinition = {
    dark:true,
    colors: {
        text: '#ccea9e',
        background: '#002951',
        surface: '#002951',
        primary: '#FFF',
        secondary: '#b6b9d0',
        accent: '#80cc28',
        "on-accent": "#002951",
        error: '#f62e36',
        info: '#50accb',
        success: '#ccea9e',
        warning: '#fcc812',
        textbox: '#b6b9d0'
    },
    variables: {
        'border-color': '#000000',
        'border-opacity': 0.12,
        'high-emphasis-opacity': 0.87,
        'medium-emphasis-opacity': 0.60,
        'disabled-opacity': 0.38,
        'activated-opacity': 0.12,
        'idle-opacity': 0.04,
        'hover-opacity': 0.12,
        'focus-opacity': 0.12,
        'selected-opacity': 0.08,
        'dragged-opacity': 0.08,
        'pressed-opacity': 0.16,
        'kbd-background-color': '#212529',
        'kbd-color': '#FFFFFF',
        'code-background-color': '#C2C2C2',
    }
}
